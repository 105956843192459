<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Komisi</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <commission-grid ref="grid" :key="gridReload" :editClick="editClick" :statusClick="statusClick"/>
                <contact-create-form ref="contactCreateForm" :reload="reload"/>
                <commission-status-form ref="CommissionStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

import commissionGrid from '../Commission/Grid/CommissionGrid.vue';
import commissionContact from '../Commission/Component/ContactDataForm.vue';
import commissionStatusForm from '../Commission/Component/CommissionStatusForm.vue';

export default {
    name: 'Commission',
    components: {
        'commission-grid': commissionGrid,
        'contact-create-form': commissionContact,
        'commission-status-form': commissionStatusForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Komisi');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.contactCreateForm.addClick();
        },
        editClick(id, view){
            const routeData = this.$router.resolve({ name: 'Commission Form', params: { formtype:'Edit', id:id, view:view, data:' ' } });
            window.open(routeData.href, '_blank');
        },
        statusClick(id, status){
            this.$refs.CommissionStatusForm.statusClick(id, status);
        },
        reload(){
            this.gridReload++;
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnAccepted").on('click', function() {
                    vue.$refs.grid.changeStatus('Accepted');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnReady").on('click', function() {
                    vue.$refs.grid.changeStatus('Ready');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnRejected").on('click', function() {
                    vue.$refs.grid.changeStatus('Rejected');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>