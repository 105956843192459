<template>
    <div>
        <div class="modal fade" id="ContactModal" tabindex="-1" aria-labelledby="ContactModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content border border-primary">
                    <div class="modal-header">
                        <h4 id="ContactModalLabel" class="font-weight-bold">Add Kontak</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :pageable="pageableConfig"
                            :columns="columns"
                            :resizable="true"
                            :filterable="filterableConfig"
                            :sortable="true"
                            >
                        </kendo-grid>
                        
                        <br>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

import commissionServices from '../Script/CommissionService.js';

export default {
    name: 'ContactDataForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['reload'],
    mounted:  function () {
        var createDetailClick = this.createDetailClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#CreateDetailButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            createDetailClick(dataItem);
        })
    },
    data: function () {
        return {
            dataSource: [],
            columns: [
                { title: "Action", width: 100, headerAttributes:{ style: "text-align:center; vertical-align:middle; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<div class="btn-group"> <button type="button" class="btn btn-success btn-md rounded" id="CreateDetailButton"> Create </button> </div>'
                },
                { field: 'commission_detail_estimation_number', title: "No. Detail Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'project_name', title: "Nama Project", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'max_commission', title: "Max Komisi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{ style: "text-align:right;"} },
                { field: 'given_commission', title: "Komisi yg diberikan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{ style: "text-align:right;"} },
                { field: 'head_contact_name', title: "Nama Afiliasi", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'contact_person_name', title: "Nama Kontak", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'phone_number', title: "No. Telpon", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'position', title: "Jabatan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'bank_account_number', title: "No. Akun Bank", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'bank_account_name', title: "Nama Akun Bank", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'npwp', title: "NPWP", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        addClick(){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: commissionServices.GetContactDataQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetCommissionAddableList.commission_addable_list == null){
                            return [];
                        }else{
                            return response.data.GetCommissionAddableList.commission_addable_list;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetCommissionAddableList.commission_addable_list == null){
                            return 0;
                        }else{
                            return response.data.GetCommissionAddableList.total;
                        }
                    },                    
                    model: {
                        fields: {
                            project_name: { type: "string",editable: false },
                            max_commission: { type: "number",editable: false },
                            given_commission: { type: "number",editable: false },
                            // head_contact_name: { type: "string",editable: false },
                            // contact_person_name: { type: "string",editable: false },
                            // phone_number: { type: "string",editable: false },
                            // position: { type: "string",editable: false },
                            // bank_account_number: { type: "string",editable: false },
                            // bank_account_name: { type: "string",editable: false },
                            // npwp: { type: "string",editable: false },
                        }
                    }
                },
            });
            window.$('#ContactModal').modal('show');
        },
        createDetailClick(data){
            window.$('#ContactModal').modal('hide');
            this.$router.push({ name: 'Commission Form', params: {  formtype:'Add', id:' ', view:'new', data:data.commission_detail_estimation_id } })
        },
    }
}
</script>
